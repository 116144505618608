import React from 'react';
import {Button} from 'primereact/button';
import {PageHeader} from './PageHeader';

export class EmailExists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true
        });
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                    <PageHeader />
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        Currently Registered
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Thank you again for your interest in the WIsDoM study.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Someone has already registered with the email address you provided. If you have questions, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Please click <span style={{fontWeight: "bold"}}>Next</span> to exit.
                    </div>
                    <div style={{marginTop: "25px", textAlign: "center"}}>
                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.props.onClickGoToPage('Landing')} />
                    </div>
                </div>
            );
        }
        else {
            return (<div>Loading...</div>);
        }
    }
}
