import React from 'react';
import {Button} from 'primereact/button';
import {PageHeader} from './PageHeader';

export class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true
        });
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                    <PageHeader />
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        Welcome
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Thank you for your interest in the <span style={{fontWeight: "bold"}}>Women with Inflammatory Bowel Disease and Motherhood (WIsDoM) Study</span> at Mount Sinai. The main goal of this study is to determine the outcomes of pregnancies in women with inflammatory bowel disease.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        This study will collect data from survey questions and information from your health record.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        If you are interested in learning more about joining this study, this simple walk through will first see if you are eligible and then highlight the details of what participation will involve.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        After reading about the study, we will ask you to complete a short quiz to make sure that you understand what's involved.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        You may download a copy of the full consent below for review along with the walk through. The full consent will also be available at the very end.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        If you have any questions about the study or the consent, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Please click <span style={{fontWeight: "bold"}}>Next</span> to continue.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        If you do not want to participate in the study or continue in the consent process, you can close the window at any time to exit.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        <span style={{color: "black", fontSize: "x-small"}}>Attachment:</span>
                        <span className="pi pi-file-pdf"></span>
                        <a style={{color: "blue"}} href="Wisdom_consent_2023_09_11.pdf" target="_blank" rel="noreferrer">Wisdom_consent_2023_09_11.pdf</a>
                        <span style={{color: "black", fontSize: "x-small"}}>(339 KB)</span>
                    </div>
                    <table style={{marginTop: "25px", width: "100%"}}>
                        <tr>
                            <td style={{textAlign: "left"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.props.onClickGoToPage('Landing')} />
                            </td>
                            <td style={{textAlign: "right"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.props.onClickGoToPage('Eligibility Criteria')} />
                            </td>
                        </tr>
                    </table>
                </div>
            );
        }
        else {
            return (<div>Loading...</div>);
        }
    }
}
