import React from 'react';
import {Button} from 'primereact/button';
import {PageHeader} from './PageHeader';

export class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true
        });
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                    <PageHeader />
                    <div style={{textAlign: "right"}}>
                        <Button label="Already registered?" onClick={this.props.onClickGoToPage('Already registered')} />
                    </div>
                    <div style={{marginTop: "15px"}}>
                        This research study is an opportunity to help understand the impact of Inflammatory Bowel Disease (IBD) on fertility.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Approximately 50% of patients with Crohn's disease or ulcerative colitis are women, and many are diagnosed during peak childbearing years. Both patients and providers frequently ask whether IBD, IBD surgery, or IBD medications impact a woman's ability to become pregnant. Despite many advances in the field, several important questions remain unanswered.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        The results of this study will help to answer these questions and provide essential information to IBD patients and their care teams in order to ensure that patients are supported during their journey to motherhood. If you have IBD, you may be eligible to join.
                    </div>
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        Who can participate in WIsDoM?
                    </div>
                    <div style={{marginTop: "15px"}}>
                        <div>&bull; Female with IBD</div>
                        <div>&bull; 18-45 years old</div>
                        <div>&bull; Planning to become pregnant in the next 18 months</div>
                        <div>&bull; Able to consent to participation</div>
                    </div>
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        What does participation involve?
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Once consent is obtained, you will be asked to complete an initial survey, and the research team will contact you to collect information about your IBD history. You will then be asked to complete monthly surveys while trying to conceive, and then for an additional four months after becoming pregnant. You may choose to opt out of the study at any time.
                    </div>
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        Additional questions?
                    </div>
                    <div style={{marginTop: "15px"}}>
                        If you have additional questions, email us at <a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>.
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Principal Investigator (Head Researcher): Marla Dubinsky, MD
                        <br />
                        STUDY-16-00948
                    </div>
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        How to enroll and participate?
                    </div>
                    <div style={{marginTop: "15px"}}>
                        If you are interested in participating in this research study, please click <span style={{fontWeight: "bold"}}>Get Started</span> below.
                    </div>
                    <div style={{marginTop: "25px", textAlign: "center"}}>
                        <Button id={"get-started-button"} className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Get Started" onClick={this.props.onClickGoToPage('Welcome')} />
                    </div>
                </div>
            );
        }
        else {
            return (<div>Loading...</div>);
        }
    }
}
