import React from 'react';
import {Button} from 'primereact/button';
import {PageHeader} from './PageHeader';

export class StudyTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true
        });
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                    <PageHeader />
                    <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                        Study Tasks and Time Commitment
                    </div>
                    <div style={{marginTop: "15px"}}>
                        To participate in this study, we will ask you to:
                        <div>&bull; Complete an interview via telephone or online.</div>
                        <div>&bull; Complete monthly health surveys via telephone or online.</div>
                        <div>&bull; Allow researchers access to your information in your medical record.</div>
                        <div>&bull; Agree to have private information (name, address, phone number) and study data collected and stored at Icahn School of Medicine at Mount Sinai.</div>
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Registering for the study and completing the initial survey should take 30 minutes or less.  You can stop and restart where you left off at any time. 
                    </div>
                    <div style={{marginTop: "15px"}}>
                        Your participation in the study will then include shorter monthly surveys that take a few minutes to complete. 
                    </div>
                    <div style={{marginTop: "25px", textAlign: "center"}}>
                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.props.onClickGoToPage('Privacy')} />
                    </div>
                </div>
            );
        }
        else {
            return (<div>Loading...</div>);
        }
    }
}
