import React from 'react';
import $ from 'jquery';
import validator from 'validator';
import SignaturePad from 'react-signature-canvas';

import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {Dropdown} from 'primereact/dropdown';
import {InputMask} from 'primereact/inputmask';
import {InputText} from 'primereact/inputtext';
import {Message} from 'primereact/message';
import {RadioButton} from 'primereact/radiobutton';
import {Toast} from 'primereact/toast';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import {PageHeader} from './PageHeader';
import {Landing} from './Landing';
import {Welcome} from './Welcome';
import {EmailExists} from './EmailExists';
import {StudyTasks} from './StudyTasks';
import {Privacy} from './Privacy';
import {DataCollection} from './DataCollection';
import {Recontacting} from './Recontacting';
import {PotentialBenefits} from './PotentialBenefits';
import {PotentialRisks} from './PotentialRisks';
import {Withdrawing} from './Withdrawing';
import {Confidentiality} from './Confidentiality';
import {FilledPDF} from './FilledPDF';

import {RTLForm} from './RTLForm';
import {baselinePatientElements} from "./BaselinePatient";
import {monthlyFollowUpElements} from "./MonthlyFollowUp";

export class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            view: 'Landing',
            eligibilityCriteriaFormData: {},
            eligibilityCriteriaFormErrors: {},
            contactInformationFormData: {},
            contactInformationFormErrors: {},
            quizData: {},
            breakTimeData: {},
            baselinePatientFormVisible: false,
            monthlyFollowUpFormVisible: false,
            enrolleeAccessData: {},
            consentFormData: {}
        };
    }

    componentDidMount() {
        // console.log('Executing componentDidMount.');
        $.post("./api/get_user.php", {}, function(response) {
            // This is temporary code that is in place until the production database is ready.
            // When ready, remove the code and uncomment out the rest of the code.
            // this.setState({
            //     isLoaded: true,
            //     sites: []
            // });

            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        isLoaded: true,
                        sites: response.data.sites
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    render() {
        if (this.state.isLoaded) {
            let siteOptions = this.state.sites.map(function(value) {
                return {label: value.site_name, value: value.site_name};
            });
            siteOptions.push({label: 'Other', value: 'Other'});
            // console.log(siteOptions);

            switch (this.state.view) {
                case 'Landing':
                    return (
                        <Landing onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Welcome':
                    return (
                        <Welcome onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Eligibility Criteria':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Eligibility Criteria
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Before you begin the walk through of the consent, the study team will need to determine if you meet the qualifications to join this study.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Please answer the questions below and click <span style={{fontWeight: "bold"}}>Next</span> when finished.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Your responses will be kept for tracking purposes and general reporting of how many people completed the screening, how many and why individuals didn't meet inclusion criteria, and how many decided not to participate in the research study. This information will be stored without your contact information if you are ineligible for the study and unlinked to your contact information if you choose not to participate in the study.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you do not wish to answer these questions or do not want to participate in the study, you can close the window at any time to exit.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you have any questions about the study or the consent, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                            </div>
                            <table style={{marginTop: "15px"}}>
                                <tr>
                                    <td><span style={{color: "red"}}>*</span> Indicates a required field</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Were you assigned female at birth, such as on an original birth certificate?</td>
                                    <td>
                                    <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_female')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_female') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_female')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_female') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'is_female') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'is_female')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Are you 18 years of age or older?</td>
                                    <td>
                                    <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_18_or_older')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_18_or_older') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_18_or_older')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_18_or_older') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'is_18_or_older') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'is_18_or_older')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Are you 45 years old or younger?</td>
                                    <td>
                                    <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_45_or_younger')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_45_or_younger') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_45_or_younger')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_45_or_younger') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'is_45_or_younger') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'is_45_or_younger')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Have you been diagnosed with inflammatory bowel disease (IBD) (like ulcerative colitis or Crohn's disease)?</td>
                                    <td>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'has_ibd_diagnosis')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'has_ibd_diagnosis') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'has_ibd_diagnosis')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'has_ibd_diagnosis') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'has_ibd_diagnosis') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'has_ibd_diagnosis')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Have you had your uterus surgically removed?</td>
                                    <td>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'was_uterus_removed')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'was_uterus_removed')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'was_uterus_removed') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'was_uterus_removed')} />
                                        }
                                    </td>
                                </tr>
                                {this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '0' &&
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Are you currently pregnant?</td>
                                    <td>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_currently_pregnant')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_currently_pregnant') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_currently_pregnant')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_currently_pregnant') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'is_currently_pregnant') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'is_currently_pregnant')} />
                                        }
                                    </td>
                                </tr>
                                }
                                {this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '0' &&
                                        this.getValue('eligibilityCriteriaFormData', 'is_currently_pregnant') === '0' &&
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Do you have a desire to become pregnant within the next 18 months?</td>
                                    <td>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="1"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_planning_pregnancy')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_planning_pregnancy') === '1'} /> Yes
                                        </label>
                                        <label className={"response"}>
                                            <RadioButton className={"p-radiobutton"}
                                                        value="0"
                                                        onChange={this.onFieldChange('eligibilityCriteriaFormData', 'is_planning_pregnancy')}
                                                        checked={this.getValue('eligibilityCriteriaFormData', 'is_planning_pregnancy') === '0'} /> No
                                        </label>
                                        {!this.isEmpty('eligibilityCriteriaFormErrors', 'is_planning_pregnancy') &&
                                        <Message severity="error" text={this.getValue('eligibilityCriteriaFormErrors', 'is_planning_pregnancy')} />
                                        }
                                    </td>
                                </tr>
                                }
                            </table>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Welcome')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickSubmitEligibilityCriteria} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Thank You':
                    if (this.state.registrant.meets_criteria === 'Yes') {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Currently Eligible
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Thank you again for your interest in the WIsDoM study.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    You have met the initial qualifications to join the study. Please click <span style={{fontWeight: "bold"}}>Next</span> to begin the consent process.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have questions, please reach out to us via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <div style={{marginTop: "25px", textAlign: "center"}}>
                                    <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Introduction')} />
                                </div>
                            </div>
                        );    
                    }
                    else {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Currently Ineligible
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Thank you again for your interest in the WIsDoM study.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    We are sorry, you do not meet the eligibility criteria to participate in this study at this time. We appreciate your interest. If you have questions about why you are ineligible, please reach out to us via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Please click <span style={{fontWeight: "bold"}}>Next</span> to exit.
                                </div>
                                <div style={{marginTop: "25px", textAlign: "center"}}>
                                    <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Landing')} />
                                </div>
                            </div>
                        );
                    }
                case 'Introduction':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Intro
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Thank you for your interest in the <span style={{fontWeight: "bold"}}>Women with Inflammatory Bowel Disease and Motherhood (WIsDoM) Study</span> at Mount Sinai. The main goal of this study is to determine the outcomes of pregnancies in women with inflammatory bowel disease.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you are interested in learning more about joining this study, this simple walk through will highlight the details of what participation involves.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                After reading about the study, we will ask you to complete a short quiz to make sure that you understand what's involved.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                You may download a copy of the full consent below for review along with the walk through. The full consent will also be available at the very end.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you have any questions about the study or the consent, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                            </div>
                            <div style={{marginTop: "15px"}}>
                                <span style={{color: "black", fontSize: "x-small"}}>Attachment:</span>
                                <span className="pi pi-file-pdf"></span>
                                <a style={{color: "blue"}} href="Wisdom_consent_2023_09_11.pdf" target="_blank" rel="noreferrer">Wisdom_consent_2023_09_11.pdf</a>
                                <span style={{color: "black", fontSize: "x-small"}}>(339 KB)</span>
                            </div>
                            <div style={{marginTop: "15px"}}>
                                To begin the walk through, please provide your contact information below and click <span style={{fontWeight: "bold"}}>Continue</span>.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you do not want to participate in the study or continue in the consent process, you can close the window at any time to exit.
                            </div>
                            <table style={{marginTop: "15px"}}>
                                <tr>
                                    <td><span style={{color: "red"}}>*</span> Indicates a required field</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> What facility do you receive IBD care from?</td>
                                    <td>
                                        <Dropdown
                                                value={this.getValue('contactInformationFormData', 'how_heard')}
                                                options={siteOptions}
                                                placeholde="Select..."
                                                onChange={this.onFieldChange('contactInformationFormData', 'how_heard')} />
                                        {this.getValue('contactInformationFormData', 'how_heard') === 'Other' &&
                                        <label className={"response"}>
                                            - specify: <InputText placeholder="Facility name" value={this.getValue('contactInformationFormData', 'other_source')} onChange={this.onFieldChange('contactInformationFormData', 'other_source')} />
                                        </label>
                                        }
                                        {!this.isEmpty('contactInformationFormErrors', 'how_heard') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'how_heard')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> First name:</td>
                                    <td>
                                        <InputText value={this.getValue('contactInformationFormData', 'screening_first_name')} onChange={this.onFieldChange('contactInformationFormData', 'screening_first_name')} />
                                        {!this.isEmpty('contactInformationFormErrors', 'screening_first_name') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'screening_first_name')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Last name:</td>
                                    <td>
                                        <InputText value={this.getValue('contactInformationFormData', 'screening_last_name')} onChange={this.onFieldChange('contactInformationFormData', 'screening_last_name')} />
                                        {!this.isEmpty('contactInformationFormErrors', 'screening_last_name') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'screening_last_name')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Phone number:</td>
                                    <td>
                                        <InputMask mask="(999) 999-9999" placeholder="(999) 999-9999" value={this.getValue('contactInformationFormData', 'screening_phone_1')} onChange={this.onFieldChange('contactInformationFormData', 'screening_phone_1')} />
                                        {!this.isEmpty('contactInformationFormErrors', 'screening_phone_1') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'screening_phone_1')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Phone number (optional):</td>
                                    <td>
                                        <InputMask mask="(999) 999-9999" placeholder="(999) 999-9999" value={this.getValue('contactInformationFormData', 'screening_phone_2')} onChange={this.onFieldChange('contactInformationFormData', 'screening_phone_2')} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Email address:</td>
                                    <td>
                                        <InputText value={this.getValue('contactInformationFormData', 'screening_email')} onChange={this.onFieldChange('contactInformationFormData', 'screening_email')} keyfilter="screening_email" />
                                        {!this.isEmpty('contactInformationFormErrors', 'screening_email') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'screening_email')} />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"question"}><span style={{color: "red"}}>*</span> Preferred method of contact:</td>
                                    <td>
                                        <label className={"response"}><RadioButton className={"p-radiobutton"} value="1" onChange={this.onFieldChange('contactInformationFormData', 'screening_prefermethod')} checked={this.getValue('contactInformationFormData', 'screening_prefermethod') === '1'} /> Email</label>
                                        <label className={"response"}><RadioButton className={"p-radiobutton"} value="2" onChange={this.onFieldChange('contactInformationFormData', 'screening_prefermethod')} checked={this.getValue('contactInformationFormData', 'screening_prefermethod') === '2'} /> Phone</label>
                                        {!this.isEmpty('contactInformationFormErrors', 'screening_prefermethod') &&
                                        <Message severity="error" text={this.getValue('contactInformationFormErrors', 'screening_prefermethod')} />
                                        }
                                    </td>
                                </tr>
                            </table>
                            <div style={{marginTop: "25px", textAlign: "center"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Continue >" onClick={this.onClickSubmitContactInformation} />
                            </div>
                        </div>
                    );
                case 'Email Exists':
                    return (
                        <EmailExists onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Study Tasks':
                    return (
                        <StudyTasks onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Privacy':
                    return (
                        <Privacy onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Data Collection':
                    return (
                        <DataCollection onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Break Time 1':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Break Time
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Before moving to the next section, let's take a quick quiz.
                            </div>
                            <Divider />
                            <div style={{marginTop: "15px"}}>
                                1. To participate in this study, we will ask you to complete a baseline survey online or via telephone and ask you to complete monthly surveys (online or via telephone). We will also ask you to allow the researchers to collect information from your medical record.
                            </div>
                            <div style={{color: "red", marginBottom: "5px"}}>*</div>
                            <div>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="T" onChange={this.onFieldChange('breakTimeData', 'question1')} checked={this.getValue('breakTimeData', 'question1') === 'T'} /> True</label>
                            </div>
                            <div>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="F" onChange={this.onFieldChange('breakTimeData', 'question1')} checked={this.getValue('breakTimeData', 'question1') === 'F'} /> False</label>
                            </div>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Data Collection')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Question 1 Result')} disabled={this.isEmpty('breakTimeData', 'question1')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Question 1 Result':
                    if (this.getValue('breakTimeData', 'question1') === 'T') {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Nice job! You got it correct.
                                </div>
                                <div style={{marginTop: "15px", marginLeft: "20px", fontStyle: "italic"}}>
                                    Yes, participation in the study involves completing a baseline survey and monthly surveys online or via telephone. We will also ask you to allow the researchers to collect information from your medical record.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Let's continue reviewing important information about the study by clicking <span style={{fontWeight: "bold"}}>Next</span>.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have any questions about the study or the consent, please contact the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Break Time 1')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Re-contacting')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Oops. Looks like you answered incorrectly.
                                </div>
                                <div style={{marginTop: "15px", marginLeft: "20px", fontStyle: "italic"}}>
                                    Participation in the study involves completing a baseline survey and monthly surveys online or via telephone. We will also ask you to allow the researchers to collect information from your medical record.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Don't worry! Please review the study information by clicking <span style={{fontWeight: "bold"}}>Go Back</span>, and complete the quiz again when you are ready.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have any questions, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Break Time 1')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Re-contacting')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                case 'Re-contacting':
                    return (
                        <Recontacting onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Potential Benefits':
                    return (
                        <PotentialBenefits onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Potential Risks':
                    return (
                        <PotentialRisks onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Withdrawing':
                    return (
                        <Withdrawing onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Break Time 2':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Break Time
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Before moving to the next section, let's take another quick quiz.
                            </div>
                            <Divider />
                            <div style={{marginTop: "15px"}}>
                                2. If you have questions or if you no longer would like to participate, the study team can be reached via email (wisdom@mssm.edu) or phone (212-824-7786).
                            </div>
                            <div style={{color: "red", marginBottom: "5px"}}>*</div>
                            <div>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="T" onChange={this.onFieldChange('breakTimeData', 'question2')} checked={this.getValue('breakTimeData', 'question2') === 'T'} /> True</label>
                            </div>
                            <div>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="F" onChange={this.onFieldChange('breakTimeData', 'question2')} checked={this.getValue('breakTimeData', 'question2') === 'F'} /> False</label>
                            </div>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Withdrawing')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Question 2 Result')} disabled={this.isEmpty('breakTimeData', 'question2')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Question 2 Result':
                    if (this.getValue('breakTimeData', 'question2') === 'T') {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Nice job! You got it correct.
                                </div>
                                <div style={{marginTop: "15px", marginLeft: "20px", fontStyle: "italic"}}>
                                    Yes, the research study team can be reached via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786), for questions about the study and about participation in the study.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Let's continue reviewing important information about the study by clicking <span style={{fontWeight: "bold"}}>Next</span>.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have any questions about the study or the consent, please contact the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Break Time 2')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Confidentiality')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Oops. Looks like you answered incorrectly.
                                </div>
                                <div style={{marginTop: "15px", marginLeft: "20px", fontStyle: "italic"}}>
                                    The research study team can be reached via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786), for questions about the study and about participation in the study.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Don't worry! Please review the study information by clicking <span style={{fontWeight: "bold"}}>Go Back</span>, and complete the quiz again when you are ready.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have questions, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Break Time 2')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Confidentiality')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                case 'Confidentiality':
                    return (
                        <Confidentiality onClickGoToPage={this.onClickGoToPage} />
                    );
                case 'Take Quiz':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Comprehension Quiz
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Thank you for learning about our study. Let's take the final quiz.
                            </div>
                            <div style={{marginTop: "15px", fontWeight: "bold"}}>
                                Please read each question carefully and choose the best answer to each one. Once finished, click <span style={{fontWeight: "bold"}}>Next</span>.
                            </div>
                            <div style={{marginTop: "15px", fontWeight: "bold"}}>
                                If you would like to review the information again, you may click <span style={{fontWeight: "bold"}}>Go Back</span>.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you have any questions about the study or the consent, please contact the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                            </div>
                            <Divider />
                            <div style={{marginTop: "15px"}}>
                                1) The main purpose of this study is to:
                            </div>
                            <div style={{color: "red", marginBottom: "5px"}}>*</div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="A" onChange={this.onFieldChange('quizData', 'question1')} checked={this.getValue('quizData', 'question1') === 'A'} /> A. To determine the outcomes of pregnancies with inflammatory bowel disease.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="B" onChange={this.onFieldChange('quizData', 'question1')} checked={this.getValue('quizData', 'question1') === 'B'} /> B. To provide you with genetic information from the information you provide.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="C" onChange={this.onFieldChange('quizData', 'question1')} checked={this.getValue('quizData', 'question1') === 'C'} /> C. To provide your doctor with medical information obtained from all the information you provide us.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="D" onChange={this.onFieldChange('quizData', 'question1')} checked={this.getValue('quizData', 'question1') === 'D'} /> D. To provide your health insurer with medical information obtained from your data and family/medical history questionnaires.</label>
                            </div>
                            <Divider />
                            <div>
                                2) How are the data you provide and your personal health information stored?
                            </div>
                            <div style={{color: "red", marginBottom: "5px"}}>*</div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="A" onChange={this.onFieldChange('quizData', 'question2')} checked={this.getValue('quizData', 'question2') === 'A'} /> A. Data will only be labeled with your name and other identifiers, so that researchers will know who the data came from.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="B" onChange={this.onFieldChange('quizData', 'question2')} checked={this.getValue('quizData', 'question2') === 'B'} /> B. Data is tagged with a unique study number code so that only researchers with permission can trace it back to your identity.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="C" onChange={this.onFieldChange('quizData', 'question2')} checked={this.getValue('quizData', 'question2') === 'C'} /> C. Your data is completely anonymous and researchers can not link the sample back to you.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="D" onChange={this.onFieldChange('quizData', 'question2')} checked={this.getValue('quizData', 'question2') === 'D'} /> D. Data and health information is stored in your medical record which is viewable to you and your doctor.</label>
                            </div>
                            <Divider />
                            <div>
                                3) Under what circumstances would you expect to hear from the researchers again?
                            </div>
                            <div style={{color: "red", marginBottom: "5px"}}>*</div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="A" onChange={this.onFieldChange('quizData', 'question3')} checked={this.getValue('quizData', 'question3') === 'A'} /> A. Researchers may contact you to return genetic testing results.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="B" onChange={this.onFieldChange('quizData', 'question3')} checked={this.getValue('quizData', 'question3') === 'B'} /> B. Researchers may contact you to collect additional information about your health (completing surveys) and/or to discuss how your data might be used.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="C" onChange={this.onFieldChange('quizData', 'question3')} checked={this.getValue('quizData', 'question3') === 'C'} /> C. Researchers will never contact you again.</label>
                            </div>
                            <div style={{marginLeft: "10px"}}>
                                <label className={"response"}><RadioButton className={"p-radiobutton"} value="D" onChange={this.onFieldChange('quizData', 'question3')} checked={this.getValue('quizData', 'question3') === 'D'} /> D. Researchers may contact you to let you know of other researchers studies your sample has been included in.</label>
                            </div>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Confidentiality')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Quiz Result')} disabled={this.isEmpty('quizData', 'question1') || this.isEmpty('quizData', 'question2') || this.isEmpty('quizData', 'question3')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Quiz Result':
                    let missedBreakTimeQuestion = (this.getValue('breakTimeData', 'question1') !== 'T'
                        || this.getValue('breakTimeData', 'question2') !== 'T');
                    let missedFinalQuizQuestion = (this.getValue('quizData', 'question1') !== 'A'
                            || this.getValue('quizData', 'question2') !== 'B' || this.getValue('quizData', 'question3') !== 'B');
                    if (missedFinalQuizQuestion) {
                        this.missedFinalQuizQuestion = true;
                    }
                    if (missedBreakTimeQuestion || missedFinalQuizQuestion) {
                        let description = 'Looks like you answered one of the earlier quiz questions incorrectly, as well as one of the final quiz questions.';
                        let goBackTo = 'Break Time 1';
                        let whatToComplete = 'earlier quiz questions and final quiz';
                        if (!missedBreakTimeQuestion) {
                            description = 'Looks like you answered one of the questions on the final quiz incorrectly.';
                            goBackTo = 'Take Quiz';
                            whatToComplete = 'quiz';
                        }
                        else if (this.getValue('breakTimeData', 'question1') === 'T') {
                            goBackTo = 'Break Time 2';
                        }
                        if (!missedFinalQuizQuestion) {
                            description = 'Looks like you answered one of the earlier quiz questions incorrectly.';
                            whatToComplete = 'quiz';
                        }

                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Uh-oh
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Thank you again for your interest in the WIsDoM study.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    {description}
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Don't worry! Please review the study information by clicking <span style={{fontWeight: "bold"}}>Go Back</span>, and complete the {whatToComplete} again when you are ready. Once all the questions are answered correctly you may then continue the consent process.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have questions, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                {missedBreakTimeQuestion &&
                                <div style={{marginTop: "15px"}}>
                                    Earlier Incorrect Quiz Question(s):
                                    {this.getValue('breakTimeData', 'question1') !== 'T' &&
                                    <div style={{marginTop: "10px"}}>
                                        1. To participate in this study, we will ask you to complete a baseline survey online or via telephone and ask you to complete monthly surveys (online or via telephone). We will also ask you to allow the researchers to collect information from your medical record.
                                        <div style={{marginTop: "5px"}}>
                                            Incorrect Response: {this.getValue('breakTimeData', 'question1')}
                                        </div>
                                    </div>
                                    }
                                    {this.getValue('breakTimeData', 'question2') !== 'T' &&
                                    <div style={{marginTop: "10px"}}>
                                        2. If you have questions or if you no longer would like to participate, the study team can be reached via email (wisdom@mssm.edu) or phone (212-824-7786).
                                        <div style={{marginTop: "5px"}}>
                                            Incorrect Response: {this.getValue('breakTimeData', 'question2')}
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                                {missedFinalQuizQuestion &&
                                <div style={{marginTop: "15px"}}>
                                    Incorrect Final Quiz Question(s):
                                    {this.getValue('quizData', 'question1') !== 'A' &&
                                    <div style={{marginTop: "10px"}}>
                                        Purpose of this study:
                                        <div style={{marginTop: "5px", marginLeft: "10px", fontStyle: "italic"}}>
                                            The main purpose of this study is to determine the outcomes of pregnancies with inflammatory bowel disease (IBD) and help understand the impact of IBD on fertility.
                                        </div>
                                    </div>
                                    }
                                    {this.getValue('quizData', 'question2') !== 'B' &&
                                    <div style={{marginTop: "10px"}}>
                                        Storage of study data:
                                        <div style={{marginTop: "5px", marginLeft: "10px", fontStyle: "italic"}}>
                                            A unique code instead of your name or other information that could identify you will be used on all your study data. Your study data will be coded and stored in a manner that keeps your information as safe as possible and prevents unauthorized people from getting to your data.
                                        </div>
                                    </div>
                                    }
                                    {this.getValue('quizData', 'question3') !== 'B' &&
                                    <div style={{marginTop: "10px"}}>
                                        Contact from the researchers:
                                        <div style={{marginTop: "5px", marginLeft: "10px", fontStyle: "italic"}}>
                                            Researchers may contact you to collect additional information about your health (completing surveys) and/or to discuss how your data might be used.
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage(goBackTo)} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Answer Not Corrected')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                    else if (this.missedFinalQuizQuestion) {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Thank you for learning about our study and completing the comprehension quiz.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Nice job! You answered all the questions correctly.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    However, it looks like you may have answered one of the comprehension quiz questions incorrectly on the first try (but great job correcting it by the way).
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786) to review the study and/or the consent before signing the consent document. The study team wants to help ensure all of your questions are answered and that you understand what participation will involve.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Please click <span style={{fontWeight: "bold"}}>Next</span> to continue.
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Take Quiz')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Before Consent')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                                <PageHeader />
                                <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                    Consent Process
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Thank you for learning about our study and completing the comprehension quiz.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Nice job! You answered all the questions correctly.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Please click <span style={{fontWeight: "bold"}}>Next</span> to review and sign the consent form.
                                </div>
                                <div style={{marginTop: "15px", fontWeight: "bold"}}>
                                    By clicking <span style={{fontWeight: "bold"}}>Next</span>, you will be taken to the consent form for your review. If you would like to participate in the study, please follow the instructions on the next page for completing the consent document.
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you have any questions about the study or the consent, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    If you do not want to participate in the study or continue in the consent process, you can close the window at any time to exit.
                                </div>
                                <table style={{marginTop: "25px", width: "100%"}}>
                                    <tr>
                                        <td style={{textAlign: "left"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Take Quiz')} />
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Consent')} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        );
                    }
                case 'Before Consent':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Consent Process
                            </div>
                            <div style={{marginTop: "15px"}}>
                                The following page will be the consent form.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Please click <span style={{fontWeight: "bold"}}>Next</span> to review and sign the consent form.
                            </div>
                            <div style={{marginTop: "15px", fontWeight: "bold"}}>
                                By clicking <span style={{fontWeight: "bold"}}>Next</span>, you will be taken to the consent form for your review. If you would like to participate in the study, please follow the instructions on the next page for completing the consent document.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you have any questions about the study or the consent, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you do not want to participate in the study or continue in the consent process, you can close the window at any time to exit.
                            </div>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Take Quiz')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Consent')} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Answer Not Corrected':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{backgroundColor: "#221f72", color: "white", padding: "5px", marginTop: "20px"}}>
                                Currently Ineligible
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Thank you again for your interest in the WIsDoM study.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                We are sorry, you have answered one of the quiz questions incorrectly and do not meet the eligibility criteria to participate in this study at this time.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786) to help assist with review of the consent if you are still interested in participating in the study.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                We, again, appreciate your interest in our study.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Please click <span style={{fontWeight: "bold"}}>Next</span> to exit.
                            </div>
                            <div style={{marginTop: "15px", fontStyle: "italic"}}>
                                The WIsDoM Study Team
                            </div>
                            <div style={{marginTop: "25px", textAlign: "center"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickGoToPage('Landing')} />
                            </div>
                        </div>
                    );
                case 'Consent':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px", fontWeight: "bold"}}>
                                WIsDoM Electronic Consent
                            </div>
                            <div style={{marginTop: "15px"}}>
                                Thank you again for your interest in the WIsDoM study. Please review the consent document below. If you would like to participate in the study, at the end of the page please:
                            </div>
                            <div style={{marginTop: "15px", marginLeft: "20px"}}>
                                1. Click the statement confirming that you have reviewed and understand the study information, all your questions about the research study have been answered at this time, and you voluntarily agree to take part in the research study.
                                <br />
                                2. Complete the Use of Your Data question(s).
                                <br />
                                3. Sign the document.
                                <br />
                                4. Enter your full name.
                                <br />
                                5. Enter the date and time (can click the Now button).
                                <br />
                                6. Click <span style={{fontWeight: "bold"}}>Next</span> and review the submitted information before clicking the certification statement and then clicking <span style={{fontWeight: "bold"}}>Submit</span>.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you have questions, please reach out to the study team via email (<a style={{color: "black"}} href="mailto:wisdom@mssm.edu" target="_blank" rel="noreferrer">wisdom@mssm.edu</a>) or phone (212-824-7786).
                            </div>
                            <div style={{marginTop: "15px"}}>
                                <embed src="Wisdom_consent_2023_09_11.pdf" type="application/pdf" width="100%" height="500px" />
                            </div>
                            <div style={{marginTop: "15px"}}>
                                <label>
                                    <Checkbox onChange={this.checkCheckbox('consentFormData', 'agree_to_take_part')} checked={this.getValue('consentFormData', 'agree_to_take_part') === '1'} /> By clicking or tapping here, you confirm that you have reviewed the information about the study. You confirm that you understand the study and all of your questions about the research study have been answered at this time. You voluntarily agree to take part in the research study.
                                </label>
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If you agree to take part in the research study, below will be the Use of Your Data questions for you to respond to. Your signature for documenting your permission to take part in this research study will be at the bottom of the page.
                            </div>
                            {this.getValue('consentFormData', 'agree_to_take_part') === '1' &&
                            <div style={{marginTop: "15px"}}>
                                <table>
                                    <tr>
                                        <td><span style={{color: "red"}}>*</span> Indicates a required field</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={"question"} style={{width: "75%"}}><span style={{color: "red"}}>*</span> Will you allow the researchers to store your data to use in current or future research studies?</td>
                                        <td style={{width: "25%"}}>
                                        <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="1"
                                                            onChange={this.onFieldChange('consentFormData', 'allow_data_use')}
                                                            checked={this.getValue('consentFormData', 'allow_data_use') === '1'} /> Yes
                                            </label>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="0"
                                                            onChange={this.onFieldChange('consentFormData', 'allow_data_use')}
                                                            checked={this.getValue('consentFormData', 'allow_data_use') === '0'} /> No
                                            </label>
                                        </td>
                                    </tr>
                                    {this.getValue('consentFormData', 'allow_data_use') === '1' &&
                                    <tr>
                                        <td className={"question"}><span style={{color: "red"}}>*</span> Do you give the researchers permission <span style={{fontWeight: "bold"}}>to contact</span> you in the future to collect additional information about you, discuss how your data might be used, or to discuss possible participation in another research project?</td>
                                        <td>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="1"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_contact')}
                                                            checked={this.getValue('consentFormData', 'permission_to_contact') === '1'} /> Yes
                                            </label>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="0"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_contact')}
                                                            checked={this.getValue('consentFormData', 'permission_to_contact') === '0'} /> No
                                            </label>
                                        </td>
                                    </tr>
                                    }
                                    {this.getValue('consentFormData', 'allow_data_use') === '1' &&
                                    <tr>
                                        <td className={"question"}><span style={{color: "red"}}>*</span> Do you give the researchers permission to keep the data indefinitely and use them for future studies that are <span style={{fontWeight: "bold", textDecoration: "underline"}}>directly related</span> to the purpose of the current study?</td>
                                        <td>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="1"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_use_data_directly_related')}
                                                            checked={this.getValue('consentFormData', 'permission_to_use_data_directly_related') === '1'} /> Yes
                                            </label>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="0"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_use_data_directly_related')}
                                                            checked={this.getValue('consentFormData', 'permission_to_use_data_directly_related') === '0'} /> No
                                            </label>
                                        </td>
                                    </tr>
                                    }
                                    {this.getValue('consentFormData', 'allow_data_use') === '1' &&
                                    <tr>
                                        <td className={"question"}><span style={{color: "red"}}>*</span> Do you give the researchers permission to keep the data indefinitely and use them for future studies that are <span style={{fontWeight: "bold", textDecoration: "underline"}}>not related</span> to the purpose of the current study (for example, a different area of research)?</td>
                                        <td>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="1"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_use_data_not_related')}
                                                            checked={this.getValue('consentFormData', 'permission_to_use_data_not_related') === '1'} /> Yes
                                            </label>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="0"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_use_data_not_related')}
                                                            checked={this.getValue('consentFormData', 'permission_to_use_data_not_related') === '0'} /> No
                                            </label>
                                        </td>
                                    </tr>
                                    }
                                    {this.getValue('consentFormData', 'allow_data_use') === '1' &&
                                    <tr>
                                        <td className={"question"}><span style={{color: "red"}}>*</span> Do you give permission to have portions of the data <span style={{fontWeight: "bold", textDecoration: "underline"}}>given to other researchers</span> at Mount Sinai or other institutions for use in research that is either related or not related to the purpose of this study?</td>
                                        <td>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="1"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_give_data')}
                                                            checked={this.getValue('consentFormData', 'permission_to_give_data') === '1'} /> Yes
                                            </label>
                                            <label className={"response"}>
                                                <RadioButton className={"p-radiobutton"}
                                                            value="0"
                                                            onChange={this.onFieldChange('consentFormData', 'permission_to_give_data')}
                                                            checked={this.getValue('consentFormData', 'permission_to_give_data') === '0'} /> No
                                            </label>
                                        </td>
                                    </tr>
                                    }
                                </table>

                                <div style={{marginTop: "15px"}}>
                                    ADULT PARTICIPANT:
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    Your signature below documents your permission to take part in this research study and to the use and disclosure of your protected health information. A signed and dated copy will be given to you.
                                </div>

                                <div style={{marginTop: "15px"}}>
                                    Signature of participant:
                                </div>
                                <div style={{borderWidth: "2px", borderStyle: "solid", maxWidth: "600px"}}>
                                    <SignaturePad canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} ref={(ref) => {this.sigCanvas = ref}} />
                                </div>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Clear" onClick={this.onClickClearSignature} />

                                <div style={{marginTop: "15px"}}>
                                    Printed name of partcipant: <InputText value={this.getValue('consentFormData', 'consent_printed_name')} onChange={this.onFieldChange('consentFormData', 'consent_printed_name')} />
                                </div>
                                <div style={{marginTop: "15px"}}>
                                    Date and time: {this.getValue('consentFormData', 'consent_given_on')}
                                </div>

                                <div style={{marginTop: "25px", textAlign: "center"}}>
                                    <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickSubmitConsent} disabled={this.isEmpty('consentFormData', 'allow_data_use') || (this.getValue('consentFormData', 'allow_data_use') === '1' && (this.isEmpty('consentFormData', 'permission_to_contact') || this.isEmpty('consentFormData', 'permission_to_use_data_directly_related') || this.isEmpty('consentFormData', 'permission_to_use_data_not_related') || this.isEmpty('consentFormData', 'permission_to_give_data'))) || this.isEmpty('consentFormData', 'consent_printed_name') || this.isEmpty('consentFormData', 'consent_given_on')} />
                                </div>
                                <Toast ref={(ref) => {this.sigRequired = ref}} />
                            </div>
                            }
                        </div>
                    );
                case 'Certify':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                Displayed below is a read-only copy of your survey responses. Please review it and the options at the bottom.
                            </div>
                            <div style={{marginTop: "15px"}}>
                                <FilledPDF consentFormData={this.state.consentFormData} />
                            </div>
                            <div style={{marginTop: "15px"}}>
                                <label>
                                    <Checkbox onChange={this.checkCheckbox('consentFormData', 'consent_certified')} checked={this.getValue('consentFormData', 'consent_certified') === '1'} /> I certify that all of my information in the document above is correct. I understand that clicking 'Submit' will electronically sign the form and that signing this form electronically is the equivalent of signing a physical document.
                                </label>
                            </div>
                            <div style={{marginTop: "15px"}}>
                                If any information above is not correct, you may click <span style={{fontWeight: "bold"}}>Go Back</span> to go back and correct it.
                            </div>
                            <table style={{marginTop: "25px", width: "100%"}}>
                                <tr>
                                    <td style={{textAlign: "left"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="< Go Back" onClick={this.onClickGoToPage('Consent')} />
                                    </td>
                                    <td style={{textAlign: "right"}}>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Submit" onClick={this.onClickCertify} disabled={this.getValue('consentFormData', 'consent_certified') !== '1'} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                case 'Enrolled':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                Thank you for enrolling in the study. Click <span style={{fontWeight: "bold"}}>Next</span> to complete the baseline questionnaire.
                            </div>
                            <div style={{marginTop: "25px", textAlign: "center"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Next >" onClick={this.onClickEnter} />
                            </div>
                        </div>
                    );
                case 'Already registered':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                In order to proceed, you will need to enter the email address you registered with as well as an access key. If you received a reminder to do a questionnaire, the message includes the access key, which you can enter below. If you are trying to complete your enrollment, enter your email address and click <span style={{fontWeight: "bold"}}>Send Access Key</span>. We will email you a message that includes the access key. Once you have entered both the email address and access key, click <span style={{fontWeight: "bold"}}>Enter</span>.
                            </div>
                            <table style={{marginTop: "15px"}}>
                                <tr>
                                    <td className={"question"}>Email address:</td>
                                    <td>
                                        <InputText value={this.getValue('enrolleeAccessData', 'screening_email')} onChange={this.onFieldChange('enrolleeAccessData', 'screening_email')} />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className={"question"}>Access key:</td>
                                    <td>
                                        <InputText value={this.getValue('enrolleeAccessData', 'access_key')} onChange={this.onFieldChange('enrolleeAccessData', 'access_key')} />
                                    </td>
                                    <td>
                                        <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Send Access Key" onClick={this.onClickSendAccessKey} disabled={this.isEmpty('enrolleeAccessData', 'screening_email')} />
                                        <Toast ref={(ref) => {this.accessKeyStatus = ref}} />
                                    </td>
                                </tr>
                            </table>
                            <div style={{marginTop: "25px", textAlign: "center"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Enter" onClick={this.onClickEnter} disabled={this.isEmpty('enrolleeAccessData', 'screening_email') || this.isEmpty('enrolleeAccessData', 'access_key')} />
                            </div>
                        </div>
                    );
                case 'Questionnaire Due':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                Your questionnaire is ready.
                            </div>
                            <div style={{marginTop: "25px", textAlign: "center"}}>
                                <Button className={"p-button"} style={{backgroundColor: "hotpink", fontSize: "large"}} label="Start questionnaire" onClick={this.onClickStartQuestionnaire} />
                            </div>
                            {this.state.baselinePatientFormVisible &&
                            <Dialog
                                    header="Baseline Patient"
                                    visible={this.state.baselinePatientFormVisible}
                                    onHide={this.onHide('baselinePatientFormVisible')}
                                    closable={true}
                                    contentStyle={{maxHeight: 700, overflow: 'scroll'}}
                                    baseZIndex={4}>
                                <RTLForm 
                                        elements={baselinePatientElements}
                                        onSubmit={this.processBaselinePatient}
                                        data={{screening_email: this.state.enrolleeAccessData.screening_email, access_key: this.state.enrolleeAccessData.access_key}} />
                            </Dialog>
                            }
                            {this.state.monthlyFollowUpFormVisible &&
                            <Dialog
                                    header="Monthly Follow-Up"
                                    visible={this.state.monthlyFollowUpFormVisible}
                                    onHide={this.onHide('monthlyFollowUpFormVisible')}
                                    closable={true}
                                    contentStyle={{maxHeight: 700, overflow: 'scroll'}}
                                    baseZIndex={5}>
                                <RTLForm 
                                        elements={monthlyFollowUpElements}
                                        onSubmit={this.processMonthlyFollowUp} 
                                        data={{screening_email: this.state.enrolleeAccessData.screening_email, access_key: this.state.enrolleeAccessData.access_key}} />
                            </Dialog>
                            }
                        </div>
                    );
                case 'Questionnaire Done':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                Thank you for completing the questionnaire. You will receive email when your next questionnaire is due.
                            </div>
                        </div>
                    );
                case 'Questionnaire Not Due':
                    return (
                        <div style={{backgroundColor: "white", textAlign: "left", margin: "20px", fontSize: "14pt"}}>
                            <PageHeader />
                            <div style={{marginTop: "15px"}}>
                                You do not have a questionnaire that is due at this time. You will receive email when your next questionnaire is due.
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div>Error loading page</div>
                    );
            }
        }
        else {
            return (<div>Loading...</div>);
        }
    }

    checkCheckbox = (dataSource, field_name) => (e) => {
        var formData = Object.assign({}, this.state[dataSource]);
        formData[field_name] = (e.target.checked ? '1' : '0');
        if (field_name === 'agree_to_take_part') {
            let consent_given_on = null;
            if (e.target.checked) {
                let today = new Date();
                let month = ('').concat(today.getMonth() + 1).padStart(2, '0');
                let day = ('').concat(today.getDate()).padStart(2, '0');
                let year = ('').concat(today.getFullYear());
                let hours = ('').concat(today.getHours()).padStart(2, '0');
                let minutes = ('').concat(today.getMinutes()).padStart(2, '0');
                consent_given_on = month + '-' + day + '-' + year + ' ' + hours + ':' + minutes;
            }
            formData['consent_given_on'] = consent_given_on;
        }
        this.setState({[dataSource]: formData});
    }

    error_alert = (error_message) => {
        if (error_message.indexOf('ERROR:') === 0) {
            error_message = error_message.substr(7);
            if (error_message.indexOf('CONTEXT:') > -1) {
                error_message = error_message.substr(0, error_message.indexOf('CONTEXT:'));
            }
        }
        alert(error_message);
    }

    getValue = (dataSource, fieldName) => {
        let record = this.state[dataSource];
        if (record === null || record === undefined || record[fieldName] === null || record[fieldName] === undefined ) {
            return '';
        }

        return record[fieldName];
    }

    isEmpty = (dataSource, fieldName) => {
        return (this.state[dataSource][fieldName] === undefined || this.state[dataSource][fieldName] === null
                || this.state[dataSource][fieldName] === '');
    }

    missedFinalQuizQuestion = false;

    onClickBaselinePatientForm = (e) => {
        this.setState({
            baselinePatientFormVisible: true
        });
    }

    onClickCertify = (e) => { 
        let formData = this.state.consentFormData;
        let postData = {
            id: this.state.registrant.id,
            agree_to_take_part: formData.agree_to_take_part,
            allow_data_use:  (formData.agree_to_take_part === '1' ? formData.allow_data_use : null),
            permission_to_contact: (formData.agree_to_take_part === '1' && formData.allow_data_use === '1' ? formData.permission_to_contact : null),
            permission_to_use_data_directly_related: (formData.agree_to_take_part === '1' && formData.allow_data_use === '1' ? formData.permission_to_use_data_directly_related : null),
            permission_to_use_data_not_related: (formData.agree_to_take_part === '1' && formData.allow_data_use === '1' ? formData.permission_to_use_data_not_related : null),
            permission_to_give_data: (formData.agree_to_take_part === '1' && formData.allow_data_use === '1' ? formData.permission_to_give_data : null),
            consent_signature: formData.consent_signature,
            consent_printed_name: formData.consent_printed_name,
            consent_given_on: formData.consent_given_on,
            consent_certified: formData.consent_certified
        };

        console.log(postData);

        $.post("./api/save_consent.php", postData, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Enrolled',
                        enrolleeAccessData: response.data
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");

    }

    onClickClearSignature = (e) => {
        this.sigCanvas.clear();
    }

    onClickEnter = (e) => {
        console.log(this.state.enrolleeAccessData);
        let script = "./api/get_questionnaire.php"
        if (this.state.isStillRegistering) {
            script = "./api/get_registrant.php"
        }
        $.post(script, this.state.enrolleeAccessData, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    if (this.state.isStillRegistering) {
                        this.setState({
                            contactInformationFormErrors: {},
                            view: 'Study Tasks',
                            registrant: response.data
                        });
                    }
                    else {
                        if (response.survey_sequence === -1) {
                            this.setState({
                                view: 'Questionnaire Not Due'
                            });
                        }
                        else {
                            this.setState({
                                view: 'Questionnaire Due',
                                survey_sequence: response.survey_sequence,
                                screening_email: response.screening_email,
                                access_key: response.access_key
                            });
                        }
                    }
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickSendAccessKey = (e) => {
        console.log(this.state.enrolleeAccessData);
        $.post("./api/send_access_key.php", this.state.enrolleeAccessData, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.accessKeyStatus.show({severity:'info', summary: 'Info', detail: 'An email message has been sent.', life: 3000});
                    this.setState({isStillRegistering: true})
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickGoToPage = (page) => (e) => {
        this.setState({
            view: page
        });
    }

    onClickMonthlyFollowUpForm = (e) => {
        this.setState({
            monthlyFollowUpFormVisible: true
        });
    }

    onClickStartQuestionnaire = (e) => {
        if (this.state.survey_sequence === '0') {
            this.setState({
                baselinePatientFormVisible: true
            });
        }
        else {
            this.setState({
                monthlyFollowUpFormVisible: true
            });
        }
    }

    onClickSubmitConsent = (e) => {
        if (this.sigCanvas.isEmpty()) {
            this.sigRequired.show({severity: 'warn', summary: 'Warning', detail: 'Your signature is required.', life: 3000});
            return;
        }

        var formData = Object.assign({}, this.state['consentFormData']);
        formData['consent_signature'] = this.sigCanvas.toDataURL();
        this.setState({
            consentFormData: formData,
            view: 'Certify'});
    }

    onClickSubmitContactInformation = (e) => {
        let contactInformationFormErrors = {}
        if (this.isEmpty('contactInformationFormData', 'how_heard') ||
                (this.state.contactInformationFormData.how_heard === 'Other' && this.isEmpty('contactInformationFormData', 'other_source'))) {
            contactInformationFormErrors.how_heard = 'Response is required';
        }
        if (this.isEmpty('contactInformationFormData', 'screening_first_name')) {
            contactInformationFormErrors.screening_first_name = 'Response is required';
        }
        if (this.isEmpty('contactInformationFormData', 'screening_last_name')) {
            contactInformationFormErrors.screening_last_name = 'Response is required';
        }
        if (this.isEmpty('contactInformationFormData', 'screening_email')) {
            contactInformationFormErrors.screening_email = 'Response is required';
        }
        else if (!validator.isEmail(this.state.contactInformationFormData.screening_email)) {
            contactInformationFormErrors.screening_email = 'Invalid response';
        }
        if (this.isEmpty('contactInformationFormData', 'screening_phone_1')) {
            contactInformationFormErrors.screening_phone_1 = 'Response is required';
        }
        if (this.isEmpty('contactInformationFormData', 'screening_prefermethod')) {
            contactInformationFormErrors.screening_prefermethod = 'Response is required';
        }
        if (Object.keys(contactInformationFormErrors).length !== 0) {
            this.setState({contactInformationFormErrors: contactInformationFormErrors});
            return;
        }

        let formData = this.state.contactInformationFormData;
        let postData = {
            id: this.state.registrant.id,
            how_heard: formData.how_heard,
            other_source: (formData.how_heard === 'Other' ? formData.other_source : null),
            screening_first_name: formData.screening_first_name,
            screening_last_name: formData.screening_last_name,
            screening_email: formData.screening_email,
            screening_phone_1: formData.screening_phone_1,
            screening_phone_2: formData.screening_phone_2,
            screening_prefermethod: formData.screening_prefermethod
        };

        console.log(postData);
        $.post("./api/save_contact_information.php", postData, function(response) {
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    if (response.email_exists === 'Yes') {
                         this.setState({
                            contactInformationFormData: {},
                            contactInformationFormErrors: {},
                            view: 'Email Exists',
                            registrant: {}
                        });
                    }
                    else {
                        this.setState({
                            contactInformationFormData: {},
                            contactInformationFormErrors: {},
                            view: 'Study Tasks',
                            registrant: response.data
                        });
                    }
                }
                else {
                    alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onClickSubmitEligibilityCriteria = (e) => {
        let eligibilityCriteriaFormErrors = {}
        if (this.isEmpty('eligibilityCriteriaFormData', 'is_female')) {
            eligibilityCriteriaFormErrors.is_female = 'Response is required';
        }
        if (this.isEmpty('eligibilityCriteriaFormData', 'is_18_or_older')) {
            eligibilityCriteriaFormErrors.is_18_or_older = 'Response is required';
        }
        if (this.isEmpty('eligibilityCriteriaFormData', 'is_45_or_younger')) {
            eligibilityCriteriaFormErrors.is_45_or_younger = 'Response is required';
        }
        if (this.isEmpty('eligibilityCriteriaFormData', 'has_ibd_diagnosis')) {
            eligibilityCriteriaFormErrors.has_ibd_diagnosis = 'Response is required';
        }
        if (this.isEmpty('eligibilityCriteriaFormData', 'was_uterus_removed')) {
            eligibilityCriteriaFormErrors.was_uterus_removed = 'Response is required';
        }
        if (this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '0'
                && this.isEmpty('eligibilityCriteriaFormData', 'is_currently_pregnant')) {
            eligibilityCriteriaFormErrors.is_currently_pregnant = 'Response is required';
        }
        if (this.getValue('eligibilityCriteriaFormData', 'was_uterus_removed') === '0'
                && this.getValue('eligibilityCriteriaFormData', 'is_currently_pregnant') === '0'
                && this.isEmpty('eligibilityCriteriaFormData', 'is_planning_pregnancy')) {
            eligibilityCriteriaFormErrors.is_planning_pregnancy = 'Response is required';
        }
        if (Object.keys(eligibilityCriteriaFormErrors).length !== 0) {
            this.setState({eligibilityCriteriaFormErrors: eligibilityCriteriaFormErrors});
            return;
        }

        let formData = this.state.eligibilityCriteriaFormData;
        let postData = {
            is_female: formData.is_female,
            is_18_or_older: formData.is_18_or_older,
            is_45_or_younger: formData.is_45_or_younger,
            has_ibd_diagnosis: formData.has_ibd_diagnosis,
            was_uterus_removed: formData.was_uterus_removed,
            is_currently_pregnant: (formData.was_uterus_removed === '0' ? formData.is_currently_pregnant : null),
            is_planning_pregnancy: (formData.was_uterus_removed === '0' && formData.is_currently_pregnant === '0' ? formData.is_planning_pregnancy : null)
        };

        console.log(postData);
        $.post("./api/save_eligibility_criteria.php", postData, function(response) {
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    // if (response.email_exists === 'Yes') {
                    //     this.setState({view: 'Email Exists'});
                    // }
                    this.setState({
                        eligibilityCriteriaFormData: {},
                        eligibilityCriteriaFormErrors: {},
                        view: 'Thank You',
                        registrant: response.data
                    });
                }
                else {
                    alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    processBaselinePatient = (formData) => {
        console.log(formData);
        $.post("./api/save_baseline_patient.php", formData, function(response) {
      //      console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Questionnaire Done',
                        baselinePatientFormVisible: false,
                        monthlyFollowUpFormVisible: false
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    processMonthlyFollowUp = (formData) => {
        console.log(formData);
        $.post("./api/save_monthly_followup.php", formData, function(response) {
            console.log(response);
            try {
                if (typeof response !== 'object' || response === null) {
                    console.log(response);
                    alert('API call failed.');
                }
                else if (response.status === 0) {
                    this.setState({
                        view: 'Questionnaire Done',
                        baselinePatientFormVisible: false,
                        monthlyFollowUpFormVisible: false
                    });
                }
                else {
                    this.error_alert(response.error_message);
                }
            }
            catch (err) {
                alert('Error occurred: ' + err);
            }
        }.bind(this), "json");
    }

    onFieldChange = (dataSource, fieldName) => (e) => {
        var formData = Object.assign({}, this.state[dataSource]);
        formData[fieldName] = e.target.value;
        this.setState({[dataSource]: formData});
    }

    onHide = (prop) => (e) => {
        this.setState({[prop]: false});
    }

    radioButtonResponse = (source, field_name, field_value) => {
        return (
            <label className={"response"}>
                <RadioButton className={"p-radiobutton"}
                            value={field_value}
                            onChange={this.onFieldChange(source, field_name)}
                            checked={this.getValue(source, field_name) === field_value} /> {field_value}
            </label>

        );
    }
}
